import React from 'react'

import Page from '../containers/Page'


// =============================================================================
const CommentPolicyPage = () => (
  <Page title="Comment Policy">
    <p>
      Long lists of draconian rules don't create an environment where
      creativity can flourish, but these few simple guidelines are here to allow
      us all to contribute.
    </p>
    <h1>This house believes in civil discussion</h1>
    <p>
      We all hold strong beliefs in our heads, regardless of how rational we
      feel. This is part of being human, and it's okay.  Debate is where
      we strengthen or shatter these, grow as individuals, and develop social
      relationships. <i>Ad hominem</i> attacks harsh the vibe and put every
      participant in a defensive stance; don't do that.
    </p>
    <h1>Progress human knowledge</h1>
    <p>
      Individually, we can't know much; together we can know everything
      knowable. Share your knowledge openly and admit your shortfalls willingly
      so we can all grow together.
    </p>
    <h1>Aim to enrich each other's lives</h1>
    <p>
      Civil discussion is often its own reward.  It can lead to far more than
      just learning more---it can deepen our connection to a subject or
      revitalise our passion for a subject.  Teaching is more than just helping
      someone else learn. Conversation and discussion are the vehicles of
      personal growth.
    </p>
    <h1>Cite your work</h1>
    <p>
      We're all guilty of asserting things without evidence from time to
      time, but let's not forget that assertion alone does not imply
      truth.  Cite your statements so those interested can learn more.  Judge
      your sources fairly, though.  Does that one blog on something
      <i>really</i> prove it to be true? </p>
    <p>
      You're welcome to link your own work on a subject too, but wear your
      bias on your sleeve.
    </p>
    <h1>Use English</h1>
    <p>
      Unfortunately neither I nor my comment platform are multilingual.  Please
      use English.  And please do be poetic in your use of it. Painting a
      picture with words is something enjoyable to do and to read. It's a
      rewarding form of creativity.
    </p>
  </Page>
)

// -----------------------------------------------------------------------------
export default CommentPolicyPage
